.gameJoinError {
    text-align: right;
    color: red;
    padding-top: 2px;
}

.numeric {
    text-align: right;
}

.footer {
    padding-top: 20px;
    text-align: center;
    color: #888;
    font-size: 10pt;
}